import { ChartOptions } from "chart.js";

export const PartnerPublicationTemplateAggregatedKpisChartSettings: ChartOptions =
  {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        ticks: {
          maxTicksLimit: 6,
        },
      },
    },
  };
