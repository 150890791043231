@switch (state.$type) {
  @case ("loading") {
    @for (item of EmptyArray; track item; let i = $index) {
      <app-publication-list-item-placeholder />
      @if (i < EmptyArray.length) {
        <div class="separator"></div>
      }
    }
  }

  @case ("error") {
    <app-reload
      textKey="shared.errorLoadingTheList"
      (action)="updatePublications()"
    />
  }

  @case ("no-results") {
    <app-partner-calendar-acceptance-banner
      (calendarAccepted)="updatePublications()"
    />
    <app-reload
      textKey="campaign.content.noResultsFound"
      buttonKey="campaign.content.resetSearch"
      (action)="resetFilters.emit()"
    />
  }

  @case ("list") {
    <app-partner-publication-template-aggregated-kpis [kpis]="state.kpis" />
    <app-partner-calendar-acceptance-banner
      (calendarAccepted)="updatePublications()"
    />
    <div class="publications-list">
      @for (
        item of state.publications;
        track item.publicationTemplateId;
        let i = $index
      ) {
        <app-partner-publication-template-with-summary-list-item
          [publication]="item"
        />
        <div class="separator"></div>
      }
    </div>
    <app-paginator
      [currentPage]="currentPage()"
      [totalPages]="totalPages()"
      (pageChange)="currentPage.set($event)"
    />
  }
}
