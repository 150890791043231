<span class="title">{{ "publicationTemplate.kpi.title" | translate }}</span>
<div class="kpis">
  <button
    class="kpi"
    (click)="onActionKpiSelected(Kpi.Impressions)"
    [ngClass]="{ active: activeKPI() === Kpi.Impressions }"
  >
    <span class="name">{{
      "publicationTemplate.kpi.impressions" | translate
    }}</span>
    <span class="value">{{ kpis().impressions }}</span>
  </button>
  <button
    class="kpi"
    (click)="onActionKpiSelected(Kpi.Engagement)"
    [ngClass]="{ active: activeKPI() === Kpi.Engagement }"
  >
    <span class="name">{{
      "publicationTemplate.kpi.engagement" | translate
    }}</span>
    <span class="value">{{ kpis().engagement }}</span>
  </button>
  <button
    class="kpi"
    (click)="onActionKpiSelected(Kpi.Reach)"
    [ngClass]="{ active: activeKPI() === Kpi.Reach }"
  >
    <span class="name">{{ "publicationTemplate.kpi.reach" | translate }}</span>
    <span class="value">{{ kpis().reach }}</span>
  </button>
  <button
    class="kpi"
    (click)="onActionKpiSelected(Kpi.Clicks)"
    [ngClass]="{ active: activeKPI() === Kpi.Clicks }"
  >
    <span class="name">{{ "publicationTemplate.kpi.clicks" | translate }}</span>
    <span class="value">{{ kpis().clicks }}</span>
  </button>
</div>
@if (activeKPI()) {
  <div class="chart-container">
    <canvas class="chart-canvas" #canvas></canvas>
  </div>
}
