import {
  Component,
  effect,
  EventEmitter,
  inject,
  input,
  Output,
  signal,
  untracked,
} from "@angular/core";
import { createState } from "../../../../../harmony/angular";
import { FilterOutput } from "../../../../shared/components/filtering/filter.interfaces";
import { SortingOption } from "../../../../shared/components/filtering/sorting-selector/sorting-selector.component";
import { PaginatorComponent } from "../../../../shared/components/paginator/paginator.component";
import { ReloadComponent } from "../../../../shared/components/reload/reload.component";
import { PublicationListItemPlaceholderComponent } from "../../../../shared/content/components/publication-list-item-placeholder/publication-list-item-placeholder.component";
import { AggregatedPublicationsPage } from "../../../../shared/content/data/entities/aggregated-publications-page";
import { GetPublicationAggregatedListWithSummaryInteractor } from "../../../../shared/content/domain/interactors/get-publication-aggregated-list-with-summary.interactor";
import { PublicationAggregatedListViewItemWithSummary } from "../../../../shared/content/domain/models/publication-list-view";
import { PartnerCalendarAcceptanceBannerComponent } from "../../components/partner-calendar-acceptance-banner/partner-calendar-acceptance-banner.component";
import { PartnerPublicationTemplateAggregatedKpisComponent } from "../../components/partner-publication-template-aggregated-kpis/partner-publication-template-aggregated-kpis.component";
import { PartnerPublicationTemplateWithSummaryListItemComponent } from "../../components/partner-publication-template-with-summary-list-item/partner-publication-template-with-summary-list-item.component";
import { PartnerPublicationListState } from "./partner-publication-list.state";

@Component({
  standalone: true,
  selector: "app-partner-publication-list",
  templateUrl: "./partner-publication-list.component.html",
  styleUrls: ["./partner-publication-list.component.scss"],
  imports: [
    ReloadComponent,
    PublicationListItemPlaceholderComponent,
    PartnerPublicationTemplateAggregatedKpisComponent,
    PaginatorComponent,
    PartnerPublicationTemplateWithSummaryListItemComponent,
    PartnerCalendarAcceptanceBannerComponent,
  ],
})
export class PartnerPublicationListComponent {
  protected readonly state = createState<PartnerPublicationListState>({
    $type: "loading",
  });
  private readonly getPublicationsWithSummaries = inject(
    GetPublicationAggregatedListWithSummaryInteractor,
  );
  private readonly pageSize = 10;
  protected readonly EmptyArray = Array.from({ length: this.pageSize });

  public readonly filters = input<FilterOutput[]>([]);
  public readonly sorting = input.required<SortingOption>();
  protected readonly currentPage = signal(1);
  protected readonly totalPages = signal(0);
  @Output() public readonly resetFilters = new EventEmitter<void>();

  constructor() {
    effect(async () => {
      this.updatePublications();
    });
  }

  protected updatePublications(): void {
    const filters = this.filters();
    const sorting = this.sorting();
    const page = this.currentPage();
    untracked(() => {
      this.getPublications(filters, sorting, page);
    });
  }

  protected async getPublications(
    filters: FilterOutput[],
    sorting?: SortingOption,
    pageIndex?: number,
  ): Promise<void> {
    this.state.set({ $type: "loading" });
    try {
      const result = await this.getListWithSummaries(
        filters,
        sorting,
        pageIndex,
      );

      if (result.totalSize === 0) {
        this.state.set({ $type: "no-results" });
        return;
      }

      this.state.set({
        $type: "list",
        publications: result.entries,
        kpis: result.kpis,
      });
      this.totalPages.set(Math.ceil(result.totalSize / this.pageSize));
    } catch {
      this.state.set({ $type: "error" });
    }
  }

  private async getListWithSummaries(
    filters: FilterOutput[],
    sorting?: SortingOption,
    pageIndex?: number,
  ): Promise<
    AggregatedPublicationsPage<PublicationAggregatedListViewItemWithSummary>
  > {
    return await this.getPublicationsWithSummaries.execute(
      this.pageSize,
      pageIndex,
      filters,
      sorting,
    );
  }
}
