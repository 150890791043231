import { NgClass } from "@angular/common";
import {
  Component,
  effect,
  ElementRef,
  input,
  signal,
  ViewChild,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Chart } from "chart.js";
import dayjs from "dayjs";
import { ChartJSLineChart } from "../../../../shared/charts/adapters/line-chart";
import { ChartDataGroup } from "../../../../shared/charts/models/chart-data-group.model";
import { ChartData } from "../../../../shared/charts/models/chart-data.model";
import { ChartComponent } from "../../../../shared/components/chart/chart.component";
import {
  PublicationAggregatedKPIs,
  PublicationAggregatedKPIsDetail,
} from "../../../../shared/content/data/entities/publication.entity";
import { ContentMetricsColor } from "../../../../shared/enums/colors";
import { PartnerPublicationTemplateAggregatedKpisChartSettings } from "./partner-publication-template-aggregated-kpis-chart-settings";

enum PublicationAggregatedKpi {
  Impressions = "impressions",
  Engagement = "engagement",
  Reach = "reach",
  Clicks = "clicks",
}

@Component({
  standalone: true,
  selector: "app-partner-publication-template-aggregated-kpis",
  templateUrl: "./partner-publication-template-aggregated-kpis.component.html",
  styleUrl: "./partner-publication-template-aggregated-kpis.component.scss",
  imports: [ChartComponent, NgClass, TranslateModule],
})
export class PartnerPublicationTemplateAggregatedKpisComponent {
  protected readonly kpis = input.required<PublicationAggregatedKPIs>();
  protected readonly activeKPI = signal<PublicationAggregatedKpi | undefined>(
    undefined,
  );
  protected readonly Kpi = PublicationAggregatedKpi;
  @ViewChild("canvas") public canvas?: ElementRef<HTMLCanvasElement>;
  private chart?: Chart;

  constructor() {
    effect(async () => {
      const kpi = this.activeKPI();
      if (kpi) {
        const kpis = this.kpis().details[kpi];
        this.createChartInstance(kpis);
      }
    });
  }

  private createChartInstance(kpis: PublicationAggregatedKPIsDetail[]): void {
    const kpi = this.activeKPI();
    if (!kpi) {
      return;
    }

    if (!this.canvas) {
      setTimeout(() => this.createChartInstance(kpis), 100);
      return;
    }

    this.chart = new ChartJSLineChart().createOrUpdate(
      this.canvas.nativeElement,
      [
        new ChartDataGroup(
          kpi,
          ContentMetricsColor,
          Object.entries(kpis).map(
            ([, item]) =>
              new ChartData(
                dayjs(new Date(item.date)).format("MMM D"),
                item.value,
              ),
          ),
        ),
      ],
      PartnerPublicationTemplateAggregatedKpisChartSettings,
      true,
    );
  }

  protected onActionKpiSelected(kpi: PublicationAggregatedKpi): void {
    if (kpi === this.activeKPI()) {
      this.activeKPI.set(undefined);
      return;
    }

    this.activeKPI.set(kpi);
  }
}
