<div class="filters-row">
  <app-aggregated-filter
    [filterTypes]="filters"
    (filtersChanged)="aggregatedFilters.set($event)"
  />
  <app-content-view-type-selector (viewTypeChange)="viewType.set($event)" />
</div>
<div class="actions-row">
  <div>
    <app-filters-row
      [interactor]="getTypesInteractor"
      [type]="FilterType.PublicationTemplateStatus"
      (optionSelected)="statusFilters.set($event)"
    />
    <app-sorting-selector
      [options]="sortingOptions"
      (optionSelected)="sortBy.set($event)"
    />
  </div>
</div>
@switch (viewType()) {
  @case (ContentViewType.List) {
    <app-partner-publication-list
      [filters]="activeFilters()"
      [sorting]="sortBy()"
      (resetFilters)="resetFilters()"
    />
  }
  @case (ContentViewType.Grid) {
    <app-partner-publication-tiles
      [filters]="activeFilters()"
      [sorting]="sortBy()"
      (resetFilters)="resetFilters()"
    />
  }
}
